.Total-Container {
    margin-top: 20px;
    padding: 10px;
  }
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* Adjust height as needed */
    padding: 40px;
}

  
  .Total-Boxes {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .Total-Box1,
  .Total-Box2,
  .Total-Box3,
  .Total-Box4,
  .Total-Box5 {
    width: 280px;
    height: 200px;
    margin: 10px;
    padding: 20px;
    border-radius: 12px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  .Total-Box1 {
    background-color: #000000;
    border: 2px solid #000000;
    color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 1);
  }
  .Total-Box2 {
    background-color: #070067;
    border: 2px solid #070067;
    color: white;
    box-shadow: 0 0 10px rgb(85, 0, 254);
  }
  .Total-Box3 {
    background-color: #38bc00;
    border: 2px solid #38bc00;
    color: white;
    box-shadow: 0 0 10px rgb(55, 255, 5);
  }
  .Total-Box4 {
    background-color: #e06602;
    border: 2px solid #e06602;
    color: white;
    box-shadow: 0 0 10px rgb(246, 180, 0);
  }
  .Total-Box5 {
    background-color: #ebbf10;
    border: 2px solid #ebbf10;
    color: white;
    box-shadow: 0 0 10px rgb(255, 218, 5);
  }
  
  .Total-Box1 h2,
  .Total-Box2 h2,
  .Total-Box3 h2,
  .Total-Box4 h2,
  .Total-Box5 h2{
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .Total-Box1 p,
  .Total-Box2 p,
  .Total-Box3 p,
  .Total-Box4 p,
  .Total-Box5 p{
    font-size: 24px;
    margin-top: 20px;
  }
  .Total-Box1 .icon ,
  .Total-Box2 .icon ,
  .Total-Box3 .icon ,
  .Total-Box4 .icon ,
  .Total-Box5 .icon {
    font-size: 48px;
    margin-bottom: 10px;
  }

  @media (max-width: 1700px) {
    .Total-Box1,
    .Total-Box2,
    .Total-Box3,
    .Total-Box4,
    .Total-Box5 {
      width: 200px;
      height: 210px;
      padding: 20px;
    }
  
  }
  @media (max-width: 1500px) {
    .Total-Box1,
    .Total-Box2,
    .Total-Box3,
    .Total-Box4,
    .Total-Box5 {
      width: 190px;
      height: 220px;
      margin: 5px;
      padding: 20px;
    }
  
  }
  @media (max-width: 1400px) {
    .Total-Box1,
    .Total-Box2,
    .Total-Box3,
    .Total-Box4,
    .Total-Box5 {
      width: 160px;
      height: 260px;
      margin: 5px;
      padding: 20px;
    }
  
  }
  
  