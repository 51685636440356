/* Ourteams.css */

.Team-Container {
    display: grid;
    justify-content: center;
    align-items: center;
    height: auto;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Auto-adjust grid columns */
    gap: 20px;
    padding: 20px; 
    text-align: center;
    background-image: url("https://ik.imagekit.io/HamzaMalik/stackbg2.png?updatedAt=1708941814049");
    background-size: cover; /* Use cover to ensure the image covers the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat;

  }
  
  .Member1 {
    background-image: url("https://ik.imagekit.io/HamzaMalik/teambg.png?updatedAt=1709105758554");
    background-size: 100% 100%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 300px; /* Fixed width */
    height: 100%; /* Occupy full height of container */
    margin: 20px auto; /* Center horizontally */
    background-color: #fff;
}
  
  .Member-image {
    width: 80%;
    height: 230px;
    overflow: hidden;
    border-radius: 50%;
    margin-left: 10%;
    margin-top: 10px;
  }
  .Member-intro h2{
    margin: 0;
    font-size: 17px;
  }
  .Member-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .Member-intro {
    padding-top: 20px;
  }
  
  .Member-intro h1{
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }

  
  .Member-Socialmediaicons {
    margin-top: 10px;
    color: #000;
    gap: 10px;
    display: flex; 
    justify-content: center;
    padding-bottom: 20px;
  }
  .Member-Socialmediaicons a{
    color: white;
    background-color: black; 
    padding: 10px; 
    border-radius: 50%; 
    transition: background-color 0.3s, color 0.3s;
  }
  .fa-facebook-f,
  .fa-twitter,
  .fa-instagram{
width: 25px;
height: 20px;

  }


