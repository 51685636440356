.edit-profile-container {
    padding: 20px;
    margin-top: 100px;
    overflow: hidden;
    display: grid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .edit-profile-container h1{
    border-bottom: 2px solid black;
    padding: 10px;
  }
  .edit-profile-container button{
    font-weight: bold;
  }
  .edit-profile-photo {
    width: 150px; /* Adjust size as needed */
    height: 150px; /* Adjust size as needed */
    border-radius: 50%; /* This makes the photo round */
    object-fit: cover; /* Ensures the image covers the area without distorting aspect ratio */
   
  }
  .edit-profile-photo1 {
    display: flex; /* Enables Flexbox */
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    height:150px; /* Adjust based on your layout needs */
    width: 150px;
    margin-bottom: 20px; /* Adds some space below the container */
    border-radius: 50%;
    border: 2px solid black;
    background-color:mediumturquoise;
    overflow: hidden;
  }
  .edit-main-profile-photo{
  display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
  }
  .edit-profile-heading{
    font-weight: bold;
    background-color: black;
    color: #ffa500;;
    width: 500px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid black;
    height: 35px;
    display: flex; /* Add flex display to align items */
    align-items: center; /* Center items vertically */
  /*  justify-content:  center;*/
  }
  .edit-profile-item {
    width: 500px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid black;
    height: 35px;
    display: flex; /* Add flex display to align items */
    align-items: center; /* Center items vertically */
  }
  
  .edit-label {
    font-weight: bold;
  }
  
  .edit-value {
    margin-left: 10px;
  }
  .edit-profile-item input{
    margin-left: 20px;
    height: 30px;
    width: 300px;
  }

  .edit-profile-photo-borwser{

    display: flex; /* Enables Flexbox */
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    margin-bottom: 20px; /* Adds some space below the container */
    overflow: hidden;
  }
  .edit-profile-photo-input{
    cursor: pointer;
    margin-left: 70px;
  }