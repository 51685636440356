.messagetest-box-container {
    display: block;
    background-image: url('https://ik.imagekit.io/HamzaMalik/stackbg2.png?updatedAt=1708941814049');
    background-color: rgba(0, 0, 0, 0.5);
    border: 2px solid black; /* Set border properties separately */
    border-radius: 2px; /* Adjust the radius as needed */
    background-size: 100% 100%;
  }
  
  @keyframes slideFromRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes slideFromLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  
  /*Chairman styling*/
  .messagetest-box2{
  display: flex;
  width: 100%;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  
  }
  .messgaetest-image {
    background-color: #fff;
    display: flex;
    width: 100%;
    overflow: hidden;
    background-size: 100% 100%;
    border-top-left-radius: 250px;
    border-bottom-left-radius: 250px;
    position: relative;
    animation: slideFromRight 1.0s ease-in-out;
    justify-content: center; /* Center children horizontally */
    align-items: center;
  }
  
  .messgaetest-image img{
    animation: slideFromRight 1.5s ease-in-out;
    
  }
  .submessagetest-box2 {
    width: 100%;
    background-image: url('https://ik.imagekit.io/HamzaMalik/stackbg2.png?updatedAt=1708941814049');
    background-size: 100% 100%;
    border-top-right-radius:250px;
    border-bottom-right-radius:250px;
    animation: slideFromLeft 1.0s ease-in-out;
  }
  .submessagetest-box2 h4{
    font-weight: bold;
    font-style: italic;
    font-size: 40px;
    color: #fff;
  margin-top: 30px;
     display: inline-block;
     justify-content:center;
    margin-bottom: -100px;
    border-bottom: 1px solid #FFB302;
    border-top: 1px solid #FFB302;
    animation: slideFromLeft 2.0s ease-in-out;
  }
  
  
  
  .messgaetest-image p{
  
  font-weight: bold;
  font-size: large;
  animation: slideFromRight 2.0s ease-in-out;
  }
  .scrollabletest-content {
    max-width: 500px;
    overflow:hidden;
    align-items: center;
    background-color: #fff;
    margin: 5px;
    margin-top: -5px;
    transition: transform 2.0s ease; /* Add a smooth transition effect */
  }
  .scrollabletest-content p {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.5); /* Add a background color with some transparency */
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #000; /* Set the text color */
    margin: 0;
  }
  
  .scrollabletest-content img {
    width: 100%;
    object-fit: cover; 
    transition: transform 0.3s ease;
  }
  
  
  .h3test{
    padding-top: 100px;
    color: #fff;
    display: inline-block;
    border-bottom: 1px solid #FFB302;
    animation: slideFromLeft 1.0s ease-in-out;
    }
    .hideh3test{
      padding-top: 100px;
      color: #fff;
      font-size: 40px;
      display: inline-block;
      border-bottom: 1px solid #FFB302;
      animation: slideFromLeft 1.0s ease-in-out;
      display: none;
    }
  
  .parastest{
      margin:20px;
      color: #fff;
  padding-right: 100px;
  padding-left: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  animation: slideFromLeft 2.0s ease-in-out;
  }
  
  
  
  
  
  
  
  /*
  
  
  .messgae-image2{
    background-color: #fff;
    width: 100%;
    overflow: hidden;
    background-size: 100% 100%;
    border-top-left-radius:250px;
    border-bottom-left-radius:250px;
    animation: slideFromRight 1.0s ease-in-out;
  }
  .messgae-image2 img{
    animation: slideFromRight 1.5s ease-in-out;
   margin-left: -550px;
   margin-bottom: -80px ;
  }
  
  .messgae-image2 p{
    justify-content: end;
  margin-left: 300px;
  font-weight: bold;
  font-size: x-large;
  animation: slideFromRight 2.0s ease-in-out;
  }
  
  */
  
  .submessagetest-box h4{
    font-weight: bold;
    font-style: italic;
    font-size: 40px;
    color: #fff;
  margin-top: 30px;
     display: inline-block;
     justify-content:center;
     margin-bottom: -100px;
    border-bottom: 1px solid #FFB302;
    border-top: 1px solid #FFB302;
    animation: slideFromRight 2.0s ease-in-out;
  }
  
  /*president*/
  
  
  
  /*CEO*/
  
  .submessagetest-box {
    width: 100%;
    background-image: url('https://ik.imagekit.io/HamzaMalik/stackbg2.png?updatedAt=1708941814049');
    background-size: 100% 100%;
    border-top-left-radius:250px;
    border-bottom-left-radius:250px;
    animation: slideFromRight 1.0s ease-in-out;
  }
  .messgaetest-image3 {
    background-color: #fff;
    display: flex;
    width: 100%;
    overflow: hidden;
    background-size: 100% 100%;
    border-top-right-radius: 250px;
    border-bottom-right-radius: 250px;
    position: relative;
    animation: slideFromLeft 1.0s ease-in-out;
    justify-content: center; /* Center children horizontally */
    align-items: center;
  }
  
  .ceotest{
    animation: slideFromLeft 1.5s ease-in-out;

  }
  
  .submessagetest-box {
    width: 100%;
    background-image: url('https://ik.imagekit.io/HamzaMalik/stackbg2.png?updatedAt=1708941814049');
    background-size: 100% 100%;
    border-top-right-radius:250px;
    border-bottom-right-radius:250px;
    animation: slideFromRight 1.0s ease-in-out;
  }
  .sirasimtest{
    width:40%;
    height: auto;
  
  }
  .messgaetest-image3 h4{
    font-weight: bold;
    font-style: italic;
    font-size: 40px;
    color: #000;
  margin-top: -500px;
     display: inline-block;
     justify-content:center;
     margin-right: 400px;
    border-bottom: 1px solid #FFB302;
    border-top: 1px solid #FFB302;
    animation: slideFromLeft 2.0s ease-in-out;
  }
  
  
  .messgaetest-image3 p{
    justify-content: end;
  margin-left: -350px;
  font-weight: bold;
  font-size: x-large;
  animation: slideFromLeft 2.0s ease-in-out;
  }
  
  .h32test{
    padding-top: 100px;
    color: #fff;
    display: inline-block;
    border-bottom: 1px solid #FFB302;
    animation: slideFromRight 1.0s ease-in-out;
    }
  .hideh32test{
    padding-top: 100px;
    font-size: 40px;
    color: #fff;
    display: inline-block;
    border-bottom: 1px solid #FFB302;
    animation: slideFromRight 1.0s ease-in-out;
    display: none;
    }
  
  .paras2test{
      margin:20px;
      color: #fff;
  padding-right: 100px;
  padding-left: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  animation: slideFromRight 2.0s ease-in-out;
  }
  
  
  
  
  .scrollabletest-content2 {
  
    max-width: 500px;
    overflow:hidden;
    align-items: center;
    background-color: #fff;
    justify-content: center;
    margin-left: 50px;
    margin-top: 50px;
    transition: transform 2.0s ease; /* Add a smooth transition effect */
  }
  .scrollabletest-content2 p {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.5); /* Add a background color with some transparency */
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #000; /* Set the text color */
    margin: 0;
  }
  
  .scrollabletest-content2 img {
    width: 100%;
    object-fit: cover; 
    transition: transform 0.3s ease;
  }
  
  
  
  
  
  
  
  
  .scrollabletest-content3 {
    max-width: 500px;
    overflow:hidden;
    align-items: center;
    background-color: #fff;
    margin: 5px;
    margin-top: -5px;
    transition: transform 2.0s ease; /* Add a smooth transition effect */
  }
  .scrollabletest-content3 p {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.5); /* Add a background color with some transparency */
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #000; /* Set the text color */
    margin: 0;
  }
  .presidenttest{
    margin-left: -70px;
  }
  .scrollabletest-content3 img {
    width: 100%;
    object-fit: cover; 
    height: 100%;
    transition: transform 0.3s ease;
  }
  
  
  
  
  
  @media (max-width: 1721px) {
    .messagetest-box-container {
      background-size: 110% 100%;
    }
    .scrollabletest-content3{
      max-width: 400px;
      margin-top: 80px;
      margin-right: 30px;
    }
   
    .scrollabletest-content img,
    .scrollabletest-content2 img,
    .scrollabletest-content3 img{
      width: 100%;
    object-fit: cover; 
    height: auto;
    transition: transform 0.3s ease;
    }
  }
  @media (max-width: 1466px) {
    .messagetest-box-container {
      background-size: 110% 100%;
    }
    .scrollabletest-content img{
      display:none;
    }
    .scrollabletest-content2 img{
      display:none;
  
    }
  
    .scrollabletest-content3 img{
      display:none;
    }
    .scrollabletest-content,
    .scrollabletest-content2{
      max-width: 500px;
      margin-top: 150px;
    }
    .scrollabletest-content3{
      max-width: 500px;
      margin-top: 150px;
    }
    
    .scrollabletest-content img{
      width: 100%;
    object-fit: cover; 
    transition: transform 0.3s ease;
    }
    .scrollabletest-content2 p{
      margin-left: -50px;
    }
  }
  
  @media (max-width: 1200px) {
  
  
    .scrollabletest-content img{
      display:none;
    }
    .scrollabletest-content2 img{
      display:none;
  
    }
  
    .scrollabletest-content3 img{
      display:none;
    }
    .submessagetest-box p,
    .submessagetest-box2 p{
      display: none;
    }
    .h3test, .h32test{
      display: none;
    }
    .hideh3test,
    .hideh32test{
  
      display: inline-block;
    }
    .scrollabletest-content2 p{
      margin-left: 0px;
    }
  }
  @media (max-width: 720px) {
  
  .ceotest{
    margin-left: -50px;
  }
  .messgaetest-image,
  .messgaetest-image3{
    border-radius: 0;
  }
  }
  @media (max-width: 600px) {
    .messagetest-box2 {
      flex-direction: column;
    }
    .messgaetest-image,
    .submessagetest-box2,
    .submessagetest-box,
    .messgaetest-image3 {
      border-radius: 0;
    }
    .scrollabletest-content img{
      display:none;
    }
    .scrollabletest-content2 img{
      display:none;
      
    }
    .h3test, .h32test{
      display: none;
    }
    .messgaetest-image{
      margin-top: 50px;
  
    }
    .messgaetest-image3{
      margin-top: 50px;
      order: 2;
    }
    .submessagetest-box{
      order: 1;
    }
    .scrollabletest-content3 img{
      display:none;
    }
  
  }
  
  @media (max-width: 450px) {
    .messgaetest-image img,
    .submessagetest-box2 h4,
    .submessagetest-box h4,
    .messgaetest-image3 h4 {
      margin-left: 0;
      margin-right: 0;
    }
    .parastest,
    .paras2test,
    .scrollabletest-content2 p,
    .scrollabletest-content3 p {
      padding: 10px;
      font-size: 14px;
    }
    .scrollabletest-content2,
    .scrollabletest-content3 {
      margin-left: 0;
      margin-top: 0;
    }
    .messgaetest-image3 {
      margin-left: 0;
      margin-right: 0;
    }
    .messagetest-box-container {
      background-size: 120% 100%;
    }
    .hideh3test,
    .hideh32test{
      margin-top: -50px;
    }
    .presidenttest{
      transform: scaleX(-1); 
  
    }
  }
  
  @media (max-width: 400px) {
    .messagetest-box-container {
      background-size: 130% 100%;
    }
  
    
  }
  
    @media (max-width: 768px) {
     .messagetest-box{
      flex: 1;
      padding: 20px;
      border-radius: 8px;
      margin-right: 10px;
      margin-top:20px ; 
     }
      .messagetest-box-container {
        flex-direction: column;
      }
     
    
      .parastest{
        display:none;
      }
      .paras2test{
        display:none;
      }
      .submessagetest-box:hover .paras
      {
        display:block ;
      }
      .submessagetest-box:hover .paras2
      {
        display:block ;
      }
    }