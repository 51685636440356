/* Footer.css */

/* Define the animation */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  
  40% {
    transform: translateY(-20px);
  }

  60% {
    transform: translateY(-10px);
  }
}
  
  /* Apply the animation to the logo class */
  .logo {
    animation: bounce 2s infinite; /* Adjust duration and timing function as needed */
  }
  