 
  .AddNews-Container {
    display: flex;
    flex-direction: column;
    gap: 80px;
}
.AddNews-Form {
    max-width: 700px;
    margin: 20px auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    background-color: #fff;
    
  }

  @media (max-width: 1500px) {
    
    .AddNews-Container {
      margin-left: 370px;
    }
  }
  
  .AddNews-Form form {
    display: flex;
    flex-direction: column;
  }
  
  .AddNews-Form input[type="text"],
  .AddNews-Form input[type="number"],
  .AddNews-Form input[type="email"],
  .AddNews-Form input[type="password"],
  .AddNews-Form textarea,
  .AddNews-Form select {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .AddNews-Form label{
    padding:10px;
    display: flex;
    font-size: large;
    font-weight: bold;
  }
  .AddNews-Form textarea {
    resize: vertical;
  }
  
  .AddNews-Form button {
    cursor: pointer;
    padding: 10px 20px;
    margin-top: 10px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    transition: background-color 0.2s ease;
  }
  
  .AddNews-Form button:hover {
    background-color: #0056b3;
  }
  
  /* Styling for dynamically added detail fields */
  .AddNews-Form div {
    margin-bottom: 10px;
  }
  .AddNews-Detial{
display: flex;
flex-direction: Column;
padding: 10px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  }