/* NewsEventDetail.css */

.news-event-detail-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .news-event-detail-image {
    width: 100%;
    max-height: 100%;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .news-event-detail-heading {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .news-event-detail-details {
    font-size: 1rem;
    line-height: 1.6;
    text-align: justify;
  }
  .news-event-detail-details h2{
    text-align: left;
    line-height: 1.6;
  }
  .error{
    background-color: red;
    color: white;
    font-size:xx-large;
  }
  @media only screen and (max-width: 600px) {
    .news-event-detail-details {
      hyphens: auto; 
    }
}