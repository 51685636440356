/* AuthorizesSalesPartner.css */
.h1stakepartner{
  font-family: 'Arial', sans-serif;
font-weight: bold;
font-size: 36px;
display: inline-block;
color: #333; /* Adjust the color as needed */
text-align: center;
margin-bottom: 20px;
text-transform: uppercase; /* Uppercase text */
letter-spacing: 2px; /* Adjust letter spacing for style */
border-bottom: 1px solid #FFB302;
padding: 10px;
margin-top: 50px;

background-image: linear-gradient(to bottom right, #e99c05, #f4ae00,	#f8bf33,	#f8ce19,  	#ffdc02, #e99c05);

border-radius: 12px;
}
.AuthorizesSalesPartner .partner-heading {
  background-image: url("https://ik.imagekit.io/HamzaMalik/stackbg2.png?updatedAt=1708941814049");
  overflow: hidden;
}

.AuthorizesSalesPartner .partner-heading p {
  font-size: medium;
  font-weight: bold;
  padding: 20px;
  margin-left: 300px;
  margin-right: 300px;
  color: azure;
}

.AuthorizesSalesPartner .partner-container {
  margin-top: 20px;
  display: flex; /* Change display to flex */
  flex-wrap: wrap; /* Enable wrapping */
  gap: 20px;
}

.AuthorizesSalesPartner .partner-card {
  width: 250px; /* Set a fixed width for each partner card */
  height: 400px;
  padding: 20px;
  margin: 10px; /* Add margin to create gap between partner cards */
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 1); /* Add box shadow */
}

.partner-card img{
  margin-top: 5px;
}
.AuthorizesSalesPartner .partner-logo {
  width: 100%; /* Make the partner logo fill its container */
  height: auto;
  margin-top: 30px;
  margin: 0 auto; /* Center the logo horizontally */
  max-width: 200px; /* Set a maximum width for the partner logo */
  max-height: 200px; /* Set a maximum height for the partner logo */
}

.AuthorizesSalesPartner .partner-name {
  font-size: 1.2rem;
  margin-bottom: 10px;
  margin-top: 20px;
}

.AuthorizesSalesPartner .partner-description {
  font-size: 0.9rem;
}

.AuthorizesSalesPartner .slick-slider {
  width: 100%; /* Ensure the slider fills its container */
}

.AuthorizesSalesPartner .slick-list {
  overflow: hidden !important; 
}



@media screen and (max-width: 1300px) {

  .partner-heading p{
    display: none;
  }

}
@media screen and (max-width: 768px) {
  .h1stakepartner{
    font-size: 20px;
  }
  .partner-heading p{
    display: none;
  }
}