.Login-Page-Detail-Container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('https://ik.imagekit.io/HamzaMalik/LoginDetail.jpg?updatedAt=1710146501494');
  background-size: 100% 100%;
  border-radius: 8px;
  width: fit-content;
  margin: auto;
 
}
  .Login-Page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
  }
  
  .Login-Page h1 {
    margin-bottom: 20px;
  }
  .Login-Page h5{
   border-bottom: 2px solid black;
   padding-bottom: 20px;
  }
  
  .Login-Page form {
    width: 500px;
    border: 2px solid #000;
    padding: 20px;
    border-radius: 8px;
    background: linear-gradient(to bottom right, rgba(136, 116, 2, 0.5), rgba(179, 161, 62, 0.5), rgba(206, 187, 82, 0.5), rgba(226, 217, 162, 0.5), rgba(249, 249, 249, 0.7));
}


  
  .logo-login-image {
    width: 100px; 
    height: auto;
    margin-right: 10px;
  }
  
  .Login-Page form div {
    margin-bottom: 15px;
  }
  
  .Login-Page label {
    display: block;
    font-weight: bold;
  }
  
  .Login-Page input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 20px;
  }
  
  .Login-Page button {
    width: 100%;
  }
  
  .Login-Page button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .Login-ForgetPassword {
    color: rgb(168, 62, 62);
    cursor: pointer;
    font-weight: bold;
    font-size: 15px;

  }
  /* Adjusted styles for PhoneInput */

/* Style for PhoneInput container */
.Login-Page form div.react-tel-input {
    width: 100%;
  }
  
  /* Style for PhoneInput input element */
  .Login-Page form div.react-tel-input input {
    width: calc(100% - 60px); /* Adjust width to match other input fields */
    padding: 20px;
    left: 60px;
    border: 1px solid #ccc;
    border-radius: 20px;
  }
  .Login-Page form div.react-tel-input .flag-dropdown {
   width: 50px;
   height: 45px;
   justify-content: center;
   left: 10px;
   border-radius: 20px;
   border: 1px solid #ccc;
  }
  .Login-Page form div.react-tel-input .flag-dropdown:hover,
  .Login-Page form div.react-tel-input .flag-dropdown:hover .flag {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
  
  