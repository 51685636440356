.Signupdetail-Container{
    width: 500px;
    height: 690px;
    border: 2px solid #000;
    padding: 20px;
    border-radius: 8px;
    border-bottom-left-radius: 50%;
    
}
.Signupdetail-Container h3{
font-family:'Jameel Noori Nastaleeq', Arial, sans-serif;
font-size:40px;
}
.Signupdetail-Container h5{
    text-align: justify;
    font-size: 17.5px;
}