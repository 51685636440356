.pageview-container{
    border: 2px solid black;
    background-color: black;
    border-radius: 12px;
    margin: 10px;
    padding: 10px;
}
.pageview-container h1,
.pageview-container h3{
    
    background: linear-gradient(45deg, #e6b81e, #8f700a , #ebc805, #ad8605);

    /* Make the background visible only through the text */
    -webkit-background-clip: text;
    background-clip: text;
  
    /* Make the text color transparent so the gradient is visible */
    color: transparent;
       
}
.pageview-icon{
    font-size: 60px;
    color:#ad8605;
}