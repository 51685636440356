/* styles/Popup.css */

.newspopup-container {
  position: fixed;
  top: 60%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 70%;
  max-width: 500px; /* Limit the maximum width of the popup */
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 100;
  text-align: center;
  border-radius: 10px; /* Add border radius for rounded corners */
}

.newsred {

  font-size: large;
}

.newsclose-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px; /* Increase the font size of the close button */
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.newspopup-content {
  margin-top: 30px; /* Adjust the margin around the content */
  color: white;
  text-align: center;
}

.newspopup-content h1 {
  font-size: 24px; /* Increase the font size of the main heading */
  color: #ff0000;
}

.newspopup-content h3 {
  font-size: 22px;
  color: #ffd700;
}

.newspopup-form {
  margin-top: 20px; /* Add margin between content and form */
}

.newspopup-form label {
  display: block;
  margin-bottom: 10px;
  color: #ffd700; /* Change label color */
}

.newspopup-form input,
.newspopup-form select {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.newspopup-form button {
  width: 100%;
  padding: 10px;
  
}


.newsvoucher {
  font-weight: bold;
  font-size: medium;
}

.newsvouchercode {
  font-weight: bold;
  font-size: large;
  color: red;
  cursor: pointer;
}

.newspopup-content p {
  margin: 10px 0;
}

@media only screen and (max-width: 768px) {
  .newspopup-container {
   position: absolute;
    width: 90%;
    max-width: 100%;
    right: 50%;
    transform: translateX(50%) translateY(-50%);
  }

  .newspopup-content {
    padding: 0 20px; /* Adjust padding for smaller screens */
  }
  .newspopup-container p{
    font-size: 8px;
  } 
}
@media only screen and (max-width: 377px) {
  .newspopup-container {
  margin-top: 100px ;
   }
  .Bank-Detail{
    display: none;

  } 
}

@media only screen and (max-width: 1900px) {
  .newspopup-container {
    right: 50%;
    transform: translateX(50%) translateY(-45%);
  }

  .newspopup-content {
    padding: 0 20px; /* Adjust padding for smaller screens */
  }
  .newsred{
    font-size: medium;
  }
  .newspopup-container p{
    font-size: medium;
  } 
}
