/* FloatingWhatsApp.css */

body {
    margin: 0;
    padding: 0;
}

.whatsapp-icon {
    position: fixed;
    max-width: 200px;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 5;
}

.whatsapp-icon img {
    width: 100px; /* Adjust the width of the icon */
    height: auto;
     /* Set a maximum height */
    border-radius: 50%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
    transition: transform 0.3s ease-in-out;
}

.whatsapp-icon img:hover {
    transform: scale(1.1);
}

/* Media query for smaller screens, adjust as needed */
@media (max-width: 768px) {
    .whatsapp-icon {
        max-width: 150px; /* Adjust the maximum width for smaller screens */
        bottom: 10px;
        right: 10px;
    }

    .whatsapp-icon img {
        width: 80px; /* Adjust the width of the icon for smaller screens */
       
    }
}

/* Media query for even smaller screens, adjust as needed */
@media (max-width: 480px) {
    .whatsapp-icon {
        max-width: 100px; /* Adjust the maximum width for even smaller screens */
        bottom: 5px;
        right: 5px;
    }

    .whatsapp-icon img {
        width: 80px; /* Adjust the width of the icon for even smaller screens */
       
    }
}
