/* styles/Popup.css */

.mainhome-popup-container {
    position: fixed;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 80%;
    max-width: 800px; /* Limit the maximum width of the popup */
    padding: 20px;
    background-color: rgba(0, 0, 0, 1);
    z-index: 101;
    text-align: center;
    border-radius: 10px; /* Add border radius for rounded corners */
  }
  
  .mainhome-red {
    color:#88ff00;
    font-size: large;
  }
  
  .mainhome-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px; /* Increase the font size of the close button */
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  .mainhome-popup-content {
    margin-top: 30px; /* Adjust the margin around the content */
    color: white;
    text-align: center;
  }
  
  .mainhome-popup-content h1 {
    font-size: 24px; /* Increase the font size of the main heading */
    color: #ffd700;
  }
  
  .mainhome-popup-content h2 {
    margin-top: 10px;
    font-size: 20px; /* Increase the font size of the subheading */
    color: #ffd700;
  }
  .mainhome-popup-content img {
    width: 400px;

  }
  
  .mainhome-popup-form {
    margin-top: 20px; /* Add margin between content and form */
  }
  
  .mainhome-popup-form label {
    display: block;
    margin-bottom: 10px;
    color: #ffd700; /* Change label color */
  }
  
  .mainhome-popup-form input,
  .mainhome-popup-form select {
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .mainhome-popup-form button {
    width: 100%;
    padding: 10px;
    
  }
  
  
  .mainhome-voucher {
    font-weight: bold;
    font-size: medium;
  }
  
  .mainhome-vouchercode {
    font-weight: bold;
    font-size: large;
    color: red;
    cursor: pointer;
  }
  
  .mainhome-popup-content p {
    margin: 10px 0;
  }
  
  @media only screen and (max-width: 768px) {
    .mainhome-popup-container {
     position: absolute;
      width: 90%;
      max-width: 100%;
      right: 50%;
      transform: translateX(50%) translateY(-50%);
    }
  
    .mainhome-popup-content {
      padding: 0 20px; /* Adjust padding for smaller screens */
    }
  }
  
  
  @media only screen and (max-width: 1900px) {
    .popup-container {
      right: 50%;
      transform: translateX(50%) translateY(-45%);
    }
  
    .popup-content {
      padding: 0 20px; /* Adjust padding for smaller screens */
    }
  }
  