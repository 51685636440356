.Logindetail-Container{
    width: 500px;
    height: 367px;
    border: 2px solid #000;
    padding: 20px;
    border-radius: 8px;
    border-bottom-right-radius: 50%;
    
}
.Logindetail-Container h3{
font-family:'Jameel Noori Nastaleeq', Arial, sans-serif;
font-size:40px;
}