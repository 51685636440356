
.Faqs-container{
    background-image: url("https://ik.imagekit.io/HamzaMalik/lighthouse.png?updatedAt=1708787556318");
    background-size:cover;
    background-repeat: no-repeat;
}
.Faqs img{
max-width: 100%;
height:500px;
}
.Faqs h1{
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    font-size: 36px;
    display: inline-block;
    color: #fff; /* Adjust the color as needed */
    text-align: center;
    margin-bottom: 20px;
    letter-spacing: 2px; /* Adjust letter spacing for style */
    border-bottom: 1px solid #FFB302;
    padding-bottom: 10px;
    margin-top: 50px;
    }
    .faqquestion {
        margin: 20px auto; /* Set margin to auto horizontally */
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        border: 5px solid #000;
    }
    