.Contactus-leads {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 2px solid black;
    background-color: #f7f1d7 ;

    max-width: 100%;
    border-radius: 12px;

  }

  
  .chart-container {
    width: 90%;
    max-width: 100%; 
    margin-bottom: 10px;
  }
  