.Dashboard-Container {
    padding: 20px;
    margin-top: 100px;
    margin-left: 310px; /* Adjust margin as needed */
    overflow: hidden;
    display: grid;
    grid-template-columns: 2fr; /* Adjust grid columns as needed */
    flex-direction: column;
    justify-content: center;
    align-items: center;
  /*  border: 2px solid black;*/
  }



  /* TextAnimation.css */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 35%;
  }
}

.typewriter {
  overflow: hidden; /* Ensures the text is not displayed outside the box */
  border-right: 0.08em solid orange; /* Typing cursor color */
  white-space: nowrap; /* Keeps text in a single line */
  margin: 0 auto; /* Centers the text */
  letter-spacing: 0.1em; /* Adjust spacing between characters */
  animation: typing 2s steps(30, end), blink-caret 0.75s step-end infinite; /* Animation settings */
}

@keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: orange; /* Blinking cursor color */
  }
}

  
 /* Dashboard.css */
.AdminListData {
  display: flex;
  margin-top: 20px; /* Add margin between AdminList and ContactUsDashboard */

}

.AdminListData > div {
  flex: 1; /* Each item takes equal space */
  max-width: 50%; /* Maximum width for each item */
}

@media (max-width: 768px) {
  .AdminListData {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: center; /* Center items horizontally */
  }

  .AdminListData > div {
    max-width: 100%; /* Full width on smaller screens */
    margin-bottom: 20px; /* Add margin between items when stacked */
  }
}
