/* PaymentPlanButton.css */

.btn42-43 {
  padding: 10px 25px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  margin-bottom: 10px; /* Add margin between button and "Select file type" text */
}

.btn-42 {
  border: 2px solid #000; /* Golden color */
  border-radius: 12px;
  z-index: 1;
  color: #000; 
  background-color: transparent;
}

.btn-42:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 11px;
  background: #887402; /* Golden color */
  background: linear-gradient(to right, #ffd700, #887402); /* Golden color gradient */
  transition: all 0.3s ease;
}

.btn-42:hover {
  color: #ffffff;
}

.btn-42:hover:after {
  top: auto;
  bottom: 0;
  height: 100%;
}

