.partners-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Adjust column size for smaller screens */
  gap: 20px;
  margin: 20px auto; /* Center align and adjust margin */
  max-width: 1200px;
}
@media (max-width: 768px) {
  .partners-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjust column size for even smaller screens */
  }
}
@media (max-width: 1700px) {
  .partners-grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); 
    margin-left: 260px;
  }
}
@media (max-width: 1600px) {
  .partners-grid {
    margin-left: 200px;
  }
}

.partner-card {
  border: 1px solid #ccc;
  padding: 16px;
  text-align: center;
}
.partner-card button{
bottom: 5px;
}
.partner-logo {
  max-width: 100%;
  height: auto;
  margin-bottom: 8px;
}
