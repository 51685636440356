.headline-container {
  width: 100%;
  overflow: hidden;
  background-color: black;
}

.right-to-left-headline {
  white-space: nowrap;
  animation: moveRightToLeftdevelopment 20s linear infinite;
}

.development-text {
  display: inline-block;
  color: white;
}

.development-red-text {
  display: inline-block;
  font-weight: bold;
  color: red;
  margin-right: 5px;
}

@keyframes moveRightToLeftdevelopment {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.development-new-container {
  justify-content: center;
  margin: 0px 0px 0px 50px; /* Center the container horizontally with equal margins */
}

.development-updates-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 20px;
  margin: 20px auto;
}

.development-update-card {
  background-color: #f9f9f9;
  padding: 20px;
  border: 2px solid black;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 1);
  overflow: hidden;
  width: 400px;
}

.development-card-image {
  max-width: 300px;
  height: auto;
  border-radius: 8px;
}

.development-card-heading {
  font-size: 1.2rem;
  margin-top: 10px;
  margin-bottom: 5px;
}

.development-card-video-details {
  font-size: 1rem;
}

.development-card-details {
  font-size: 1.5rem;
  font-family: 'Jameel Noori Nastaleeq', Arial, sans-serif;
  direction: rtl;
  line-height: 1.6;
  padding: 10px;
  border-radius: 5px;
}
@media (max-width: 950px) {
  .development-new-container {
    margin: 20px 20px; /* Adjust margin */
  }

}

/* Media query for screens smaller than 870px */
@media (max-width: 869px) {
  .development-new-container {
    margin: 20px 10px; /* Adjust margin */
  }

  .development-updates-container {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
    margin: 20px 10px;
  }

  .development-update-card {
    width: auto;
    padding: 15px;
  }

  .development-card-heading {
    font-size: 1.1rem;
    margin-top: 8px;
    margin-bottom: 3px;
  }

  .development-card-video-details,
  .development-card-details {
    font-size: 0.9rem;
  }

  .development-card-image {
    max-width: 100%;
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
}
