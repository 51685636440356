/* MemberSidebar.css */

.sidebar {
  width: 300px;
  height: calc(100vh - 100px); /* Adjust the height as needed */
  overflow-y: auto;
  background-color: #000000;
  color: #fff;
  position: fixed;
  border-top: 2px solid white;
  left: 0px;
  top: 90px;
  padding: 20px;


   scrollbar-width: thin; /* for Firefox */
  scrollbar-color: #333333 #000000; 
}

.sidebar-menu {
  list-style: none;
  padding: 0;
}

.sidebar-menu li {
  margin-bottom: 10px;
  border-bottom: 2px solid white;
  font-size: 18px;
  text-align: left;
  padding: 10px;
  cursor: pointer;
}

.sidebar-menu li a {
  color: #fff;
  text-decoration: none;
}

.sidebar-menu li:hover {
  color: #ffc04d;
}

/* Styles for dropdowns */
.dropdown {
  position: relative;
}

.dropdown-toggle {
  margin-bottom: 10px;
  border-bottom: 2px solid white;
  font-size: 18px;
  text-align: left;
  padding: 10px;
}

.dropdown-toggle:hover {
  color: #ffc04d;
}

/* Ensure submenu items also match this font size */
.sidebar-submenu li a {
  font-size: 18px;
}

/* Styles for the dropdown arrow */
.dropdown-arrow {
  color: #fff;
  position: absolute;
  top: 50%;
  margin-left: 50px; /* Adjust the positioning of the arrow */
  transform: translateY(-50%);
}

/* Styles for the logout button */
.logout {
  width: 100%;
  padding: 10px;
  background-color: #f7f7f7;
  border: none;
  color: #000;
  font-size: 18px;
  cursor: pointer;
}

/* Hover effect for the logout button */
.logout:hover {
  background-color: #ffc04d;
}


/* Media query for laptop screens with a maximum width of 1400px */
@media screen and (max-width: 1600px) {
  .sidebar {
    width: 280px; 
  }
  .sidebar-menu li {
    font-size: 16px; /* Adjust font size */
  }
  
  .dropdown-toggle {
    font-size: 14px; /* Adjust font size */
  }
  
  .sidebar-submenu li a {
    font-size: 14px; /* Adjust font size */
  }
}


/* Media query for laptop screens with a maximum width of 1400px */
@media screen and (max-width: 1400px) {
  .sidebar {
    width: 230px; 
  }
  .sidebar-menu li {
    font-size: 14px; /* Adjust font size */
  }
  
  .dropdown-toggle {
    font-size: 14px; /* Adjust font size */
  }
  
  .sidebar-submenu li a {
    font-size: 14px; /* Adjust font size */
  }
}
