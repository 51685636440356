




.contactus-container{
  background-image: url(https://ik.imagekit.io/HamzaMalik/Slider1.png?updatedAt=1708774699321);
  background-repeat: none;
  background-position:right;
}
.contactus-content {
  display: grid;
  position: relative;
}

.contact-form-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

  .additional-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 800px;
    width: 100%;
    height: 750px;
    margin: 0 auto;
    position: relative;
    border: 2px solid #000;
    background-color: rgba(0, 0, 0, 0.5);
    color:aliceblue;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 50px;
    margin-top: 30px;
  }
 
  .Contactus-form {
    max-width: 800px;
    width: 100%;
    height: auto;
    margin: 0 auto;
    position: relative;
    border: 2px solid #000;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 50px;
    margin-top: 20px;
  }
  .animated-label {
    padding-inline: 0.25rem;
    display: block;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: #fff;
    margin-bottom: 20px;
    border: 2px solid transparent;
    text-align: left; /* Align the label to the left */
  }
  
  .animated-label textarea {
    height: 200px;
  }
  
  .animated-label input{
    width: 100%;
    padding: 10px;
    border: none;
    border: transparent;
    background-color: transparent;
    border-bottom: 2px solid #ccc;
    outline: none;
    color:antiquewhite;
    transition: border-bottom 0.3s ease;
  }
  .animated-label textarea {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: transparent;
    border: 2px solid #ccc;
    outline: none;
    color:antiquewhite;
    transition: border-bottom 0.3s ease;
  }
  .animated-label input:focus,
  .animated-label textarea:focus {
    border-bottom: 4px solid #3498db;
    
  }
  .animated-label input::placeholder, 
  .animated-label textarea::placeholder {
    color:antiquewhite; 
    font-style: italic; 
  }
  
  
  .btn41-43 {
    padding: 10px 25px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    background: transparent;
    outline: none !important;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
  }
  
  .btn-43 {
    border: 2px solid #fff; /* Golden color */
    border-radius: 12px;
    z-index: 1;
    color: #fff; /* Black color */
  }
  
  .btn-43:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 11px;
    background: #887402; /* Golden color */
    background: linear-gradient(to right, #ffd700, #887402); /* Golden color gradient */
    transition: all 0.3s ease;
  }
  
  .btn-43:hover {
    color: #000000; /* Black color */
  }
  
  .btn-43:hover:after {
    top: auto;
    bottom: 0;
    height: 100%;
  }
  

  .contacth1stake {
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    position: relative;
    font-size: 36px;
    display: inline-block;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-bottom: 1px solid #FFB302;
    padding-bottom: 10px;
    margin-top: 50px;
  }
  
 .additional-para{
justify-items: flex-start;
align-content: flex-start;
 }
  
  /* Additional styling for the "Additional info" div */
  .additional-content h1 {
    font-size: 24px;
    color: #fff;
    margin-bottom: 10px;
  }
  .social-icons {
    display: flex;
    align-items: center;
    justify-content: center;
  }








  /* Your existing styles */
  @media (max-width: 1750px) {
    .contacth1stake {
      font-size: 36px;
      margin-top: 30px;
    }
  
    .contactus-content {
      grid-template-columns: 1fr;
    }
  
    .contact-form-container,
    .additional-content {
      width: 100%;
      padding: 10px;
      margin-bottom: 60px;
    }
  }
@media (max-width: 1500px) {
  .contact-form-container {
    flex-direction: column;
  }

  .contacth1stake {
    font-size: 36px;
    margin-top: 30px;
  }

  .contactus-content {
    grid-template-columns: 1fr;
   
  }
.Contactus-form{
  border: 2px solid #fff;
}
  .contact-form-container,
  .additional-content {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    background-color: #000 ;
    border: 2px solid #fff;
  }
}

@media (max-width: 768px) {
  .contact-form-container,
  .additional-content {
    padding: 20px;
  }

  .contactus-content {
    grid-template-columns: 1fr;
  }

  .contact-bg {
    margin-top: -20px;
  }

  .btn41-43 {
    padding: 8px 20px;
  }

  .additional-para,
  .animated-label input,
  .animated-label textarea {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .contact-form-container {
    flex-direction: column;
  }

  .contacth1stake {
    font-size: 36px;
    margin-top: 20px;
  }

  .contact-bg {
    margin-top: 0;
  }

  .animated-label input,
  .animated-label textarea {
    font-size: 16px;
  }

  .btn41-43 {
    font-size: 14px;
    padding: 6px 15px;
  }

  .additional-content {
    text-align: center;
  }

  .social-icons {
    flex-wrap: wrap;
  }
}

@media (max-width: 400px) {
  .contacth1stake {
    font-size:36px;
    margin-top: 30px;
  }
}
