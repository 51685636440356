.news-events-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)); /* Adjust column size for smaller screens */
  gap: 20px;
  margin: 20px auto; /* Center align and adjust margin */
  max-width: 1200px; /* Limit maximum width */
}

@media (max-width: 768px) {
  .news-events-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjust column size for even smaller screens */
  }
}

.news-event-card {
  background-color: #f9f9f9;
  padding: 20px;
  border: 2px solid black;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  align-items: center; /* Center items horizontally */
  text-align: center;
}

.news-event-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.news-event-heading {
  font-size: 1.2rem;
  margin-top: 10px;
  margin-bottom: 5px;
}

.news-event-details {
  font-size: 1rem;
}

.news-event-card button {
  margin-top: 10px;
  padding: 8px 16px;
  cursor: pointer;
}
