.AdminListData-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid black;
    background-color: #f7f1d7 ;
    max-width: 100%;
    height: 537px;
    overflow: auto;
    border-radius: 12px;

  }
  
  .AdminListData-Main {
    display: flex;
    max-width: 100%;
  }
  
  .AdminListData-Content {
    flex: 3;
    padding: 10px;
  }
  
  
  .AdminListData table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .AdminListData th, .AdminListData td {
    border: 1px solid black;
    padding: 8px;
  }
  
  .AdminListData th {
    width: 20%;
  }
  
  .AdminListData td {
    width: 20%;
  }
  
  .AdminListData td:last-child {
    width: 40%;
  }
  
  @media (max-width: 1600px) {
    .AdminListData .h1stake {
    font-size: 30px;
    }
  }
  @media (max-width: 1400px) {
  
    .AdminListData .h1stake {
      font-size: 25px;
      }
  }
  