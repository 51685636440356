.container-whyd1{
        display: flex;
        flex-direction: row;
        align-items: stretch; 
        justify-content: flex-start; 
        width: 100%;
        height: auto;
        background-image:url('https://ik.imagekit.io/HamzaMalik/whyd1bg.png?updatedAt=1708940663849');
        background-size: 100% 100%;
}

.box1 {
  flex: 1;
  height: auto;
  width: 100%;
  background-color: rgba(212, 212, 212, 0);
  margin: 80px;
  margin-top: -20px;
  overflow: hidden;
  padding: 20px; /* Adjusted padding for a cleaner look */
  box-sizing: border-box; /* Include padding in the box size calculation */
  position: relative;
  opacity: 0;
  transform: translateY(20px);
  animation: slideIn 2s forwards;
}

@keyframes slideIn {
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.box1 p{
  margin: 0;
  padding: 10px 0;
  color: #fff;
  justify-content: flex-start;
}

.box1 p:last-child {
  border-bottom: none;
}

.box2 {
  flex: 1;
  height: auto;
  width: 100%;
  background-color: rgba(212, 212, 212, 0);
  margin: 10px;
  margin-left: -40px;
  overflow: hidden;
  border-radius: 10px;
}

@keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  /* Apply the keyframes to the transition */
  .stamp img {
    max-width: 25%;
    align-items: left;
    height: auto;
    animation: pulse 1s infinite;
  }
  
.box2 video {
    max-width: 100%; 
    height: auto; 
    border: 3px solid #FFB302; 
    margin-top: 50px;
border-radius: 10px;
  
  }
 
   @media (max-width: 768px) {
    .container-whyd1 {
        flex-direction: column; /* Switch back to row for larger screens */
    }

    .box1,
    .box2 {
        width: 90%; /* Split into two columns for larger screens */
        margin: 20px;
    }
    
}