/* Header.css */
.higher-zIndex {
  z-index: 102;
}
.container-fluid {
  background-color: #000; /* Black background */
  color: #fff; /* White text */
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin-top: -10px; /* Remove or adjust this margin */
  display: flex;
  overflow: hidden;
}

.logo-image {
  width: 100px; /* Adjust the width of the image */
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px; /* Adjust the spacing between the image and text */
}

.logo-image:hover {
  transform: scale(1.1); /* Scale the image on hover */
}

.navbar-brand {
  color: #fff;
  font-weight: bold;
  margin-left: 90px;
  font-size: 1.5rem; /* Adjust the font size as needed */
  text-decoration: none; /* Remove underline style for links */
  transition: color 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  animation: fadeIn 2s ease, scaleUp 1s 2s ease forwards;
}

.brand-d1 {
  color: #e6b81e;
  font-weight: bold;
  font-size: 1.5rem; /* Adjust the font size as needed */
  text-decoration: none; /* Remove underline style for links */
}

.navbar-brand:hover { /* Corrected class name */
  color: #e6b81e;
}

.navbar-toggler-icon {
  background-color: #fff;
  border-radius: 12px;
}

.navbar-nav {
  margin-left: auto; /* Push navbar-nav to the left */
}

.nav-item {
  margin-right: 1rem;
}

.nav-link {
  color: #fff;
}

.nav-link:hover {
  color: #ffa500; /* White text with golden color on hover */
}

.navbar-toggler.collapsed .navbar-toggler-icon {
  background-color: #ffa500; /* Golden color for the toggle icon when collapsed */
}

.webportal {
  width: 20px; /* Adjust the width of the web portal icon */
  height: auto; /* Maintain the aspect ratio of the icon */
  margin-right: 5px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleUp {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.5);
  }
}




@media (max-width: 1180px) {
  .navbar-brand {
    color: #fff;
    font-weight: bold;
    margin-left: 60px;
    font-size: 1.5rem; /* Adjust the font size as needed */
    text-decoration: none;
  }

  .brand-d1 {
    color: #e6b81e;
    font-weight: bold;
    font-size: 1.5rem; /* Adjust the font size as needed */
    text-decoration: none; /* Remove underline style for links */
  }

  .navbar-nav {
    margin: auto; /* Reset margin for small screens */
  }
  .nav-item {
    margin-right: 1rem;
  }
  .logo-image {
    width: 80px; /* Adjust the width of the image */
    height: auto;
  }
}


@media (max-width: 1160px) {
  .navbar-brand {
    color: #fff;
    font-weight: bold;
    margin-left: 60px;
    font-size: 1.0rem; /* Adjust the font size as needed */
    text-decoration: none;
  }

  .brand-d1 {
    color: #e6b81e;
    font-weight: bold;
    font-size: 1.0rem; /* Adjust the font size as needed */
    text-decoration: none; /* Remove underline style for links */
  }
  .nav-item {
    margin-right: 1rem;
  }
  .navbar-nav {
    margin: auto; /* Reset margin for small screens */
  }

  .logo-image {
    width: 80px; /* Adjust the width of the image */
    height: auto;
  }
}

@media (max-width: 991px) {
  .navbar-brand {
    color: #fff;
    font-weight: bold;
    margin-left: 30px;
    font-size: 0.8rem; /* Adjust the font size as needed */
    text-decoration: none;
  }

  .brand-d1 {
    color: #e6b81e;
    font-weight: bold;
    font-size: 0.8rem; /* Adjust the font size as needed */
    text-decoration: none; /* Remove underline style for links */
  }

  .navbar-nav {
    margin: 0; /* Reset margin for small screens */
  }

  .logo-image {
    width: 50px; /* Adjust the width of the image */
    height: auto;
  }
}
