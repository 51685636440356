.adminnews-events-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)); /* Adjust column size for smaller screens */
    gap: 20px;
    margin: 20px auto; /* Center align and adjust margin */
    max-width: 1200px; /* Limit maximum width */
  }
  .table-styling{
    overflow:revert-layer;
  }
  .loading-overlay {
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  
  
  @media (max-width: 768px) {
    .adminnews-events-grid {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjust column size for even smaller screens */
    }

  }
  @media (max-width: 1700px) {
    .adminnews-events-grid {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); 
      margin-left: 250px;
    }
  
  }
  @media (max-width: 1600px) {
 
    .table-styling{
      overflow:scroll;
      width: 900px;
      margin-left:300px;
    }
    .adminnews-events-grid {
      margin-left: 200px;
    }
  }
  
  
  .news-event-card {
    background-color: #f9f9f9;
    padding: 20px;
    border: 2px solid black;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    align-items: center; /* Center items horizontally */
    text-align: center;
  }
  
  .news-event-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .news-event-heading {
    font-size: 1.2rem;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  
  .news-event-details {
    font-size: 1rem;
  }
  
  .News-Buttons button{

    margin-top: 10px;
    margin-left: 5px;
    padding: 8px 16px;
    cursor: pointer;
    width: 120px;
    
  }

  
  .AdminNews-Container {
    display: flex;
    flex-direction: column;
    gap: 80px;
}

.AdminContent {
    display: flex;
    flex-direction: row;


}
.AddNewEventButton{
  padding: 20px;
}
.AdminNews-Sidebar {
    flex: 1;
    /* Adjust width as needed, or use flex-basis if you want a fixed sidebar width */
}

.AdminNews-Main {
    flex: 10;
    /* Adjust the flex ratio according to your preference */
}

.news-events-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Adjust as needed */
    gap: 20px;
    
}

/* Add any additional styling here */
