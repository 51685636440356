/* Gallery Container */
.Gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 50px;
  padding: 16px;
  margin-bottom: 20px;
  justify-content: center;
}

/* Image Container */
.image-container {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
background-size: cover;
background-repeat: no-repeat;

}

.image-container img {
  max-width: 100%; /* Maximum width of the image is 100% of its container */
  height: auto; /* Height adjusts automatically to maintain aspect ratio */
  border-radius: 8px;
  overflow: hidden;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  opacity: 0;
} 

/* Image Info */
.image-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: translateY(100%);
}

/* Image Info Heading */
.image-info h2 {
  margin: 0;
  font-size: 16px;
}

/* Image Info Text */
.image-info p {
  margin: 5px 0 0;
  font-size: 12px;
}

/* Gallery Image Hover Effect */
.image-container:hover img {
  transform: scale(1.1);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.image-container:hover .image-info {
  opacity: 1;
  transform: translateY(0);
}



@media (max-width: 1081px) {
  .Gallery {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); /* Adjust column size for smaller screens */
    gap: 20px; /* Reduce the gap between images */
    padding: 8px; /* Decrease padding */
  }
}


@media (max-width: 496px) {
  .Gallery {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Adjust column size for smaller screens */
    gap: 20px; /* Reduce the gap between images */
    padding: 8px; /* Decrease padding */
  }
}