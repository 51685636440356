.Admin-header-container {
  background-color: #000;

}

.Admin-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: fixed; /* This fixes the navbar's position */
  top: 0; /* Aligns it to the top */
  left: 0; /* Aligns it to the left */
  width: 100%; /* Ensures it spans the full width of the viewport */
  z-index: 1000; /* Ensures it stays on top of other content */
  background-color: #000; /* Ensures the background is not transparent when scrolling */
}


.Admin-logo-container {
  display: flex;
  align-items: center;
}

.Admin-logo-image {
  width: 70px;
  height: auto;
  margin-right: 10px;
}

.Admin-navbar-brand {
  color: #e6b81e;
  font-weight: bold;
  font-size: 1.5rem;
  
  
  background: linear-gradient(45deg, #e6b81e, #8f700a , #ebc805, #ad8605);

  /* Make the background visible only through the text */
  -webkit-background-clip: text;
  background-clip: text;

  /* Make the text color transparent so the gradient is visible */
  color: transparent;

}

.Admin-brand-d1 {
  color: #e6b81e;
}

.Admin-navbar-nav {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.Admin-nav-item {
  margin-right: 1rem;
}

.Admin-nav-link {
  color: #fff;
  text-decoration: none;
}

.Admin-nav-link:hover {
  color: #ffa500;
}

.Admin-webportal {
  width: 20px;
  height: auto;
  margin-right: 5px;
}
